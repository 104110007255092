import { useContext, useEffect, useState } from "react";
import {
  Button,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { AuthContext } from "../../../../../context/context";
import useStyles from "./styles";
import { AlertModal, CardName } from "../../../../../shared/components";
import { EndosoContext } from "../../../context";
import { endoso } from "services";

function monthDiff(d1, d2) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}
function dayDiff(d1, d2, time) {
  /* validacion por debajo de 3 meses */
  if (time === 1) {
    return d1 > d2 ? true : false;
  } else return d1 !== d2 ? true : false;
}
function getAge(birthDate) {
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  const monthValidation = () => {
    const month = monthDiff(birthDate, today);
    if (month < 3) return true;
    else if (month <= 3 && dayDiff(birthDate.getDate(), today.getDate(), 1))
      return true;
    return false;
  };
  const oldMonthValidation = () => {
    const month = monthDiff(birthDate, today);
    if (month > 828) return true;
    else if (dayDiff(birthDate.getDate(), today.getDate(), 2)) return true;
    return false;
  };
  return {
    age: age,
    month: monthDiff(birthDate, today),
    underMonth: monthValidation(),
    upToMonth: oldMonthValidation(),
  };
}
const HighRiskForm = ({ setMessage, setShowMessage, idSolicitud }) => {
  const { alertData, setAlertData } = useContext(EndosoContext);
  const classes = useStyles();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fechtData = async () => {
      if (idSolicitud) {
        const resp = await endoso.getCategories605(idSolicitud);
        setCategories(resp?.data?.categorias || []);
      }
    }
    fechtData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const generos = [
    { id: "HOMBRE", name: "Hombre" },
    { id: "MUJER", name: "Mujer" },
  ];

  const [person, setPerson] = useState({ typper: 1 });
  const [disabled, setDisabled] = useState(true);
  const { persons, setPersons, setTimer } = useContext(AuthContext);

  /* methods */

  const handleChange = (e) => {
    setTimer(0);
    if (typeof e.target.value === "string")
      setPerson({ ...person, [e.target.name]: e.target.value?.toUpperCase() });
    else setPerson({ ...person, [e.target.name]: e.target.value });
  };

  const handleValidateAge = (date, codigo) => {
    const years = getAge(date);
    let isValid = false;
    if (categories.length === 1) {
      isValid = years.age >= 18 && years.age <= 60;
    } else {
      switch (codigo) {
        case '001':
          isValid =
            (years.age === 0 && years.month >= 3 && !years.underMonth) ||
            (years.age > 0 && years.age <= 60)
          break;

        case '002':
          isValid =
            (years.age >= 61 && years.age <= 64);
          break;

        case '003':
          isValid =
            (years.age >= 65 && years.age <= 69);
          break;

        case '004':
          isValid = years.age >= 70;
          break;

        case '005':
          isValid = true;
          break;

        default:
          console.warn('Código no reconocido');
          return;
      }
    }

    if (!isValid) {
      setMessage({
        text: 'El movimiento no puede ser realizado por estar fuera del rango de edad, el endoso debe enviarse al proceso tradicional.',
        severity: 'warning',
      });
      setShowMessage(true);
    }

    return isValid;
  };


  const handleSubmitData = () => {
    setTimer(0);
    const isAgeValid = handleValidateAge(person?.fechainicial, person?.categoria?.toString());
    if (isAgeValid) {
      const dateIni = new Date(person?.fechainicial);
      const dateIntial =
        dateIni.getDate() +
        "/" +
        (dateIni.getMonth() + 1) +
        "/" +
        dateIni.getFullYear();
      let fullName;
      if (person?.segundoNombre) {
        fullName = `${person?.primerNombre} ${person?.segundoNombre} ${person?.apellidoPaterno} ${person?.apellidoMaterno}`;
        setPersons([
          ...persons,
          { ...person, fechainicial: dateIntial, nombre: fullName },
        ]);
        setPerson({ typper: 1 });
      } else {
        fullName = `${person?.primerNombre} ${person?.apellidoPaterno} ${person?.apellidoMaterno}`;
        setPersons([
          ...persons,
          { ...person, fechainicial: dateIntial, nombre: fullName },
        ]);
        setPerson({ typper: 1 });
      }
    }
  };

  /* validator */
  useEffect(() => {
    if (
      person?.apellidoPaterno &&
      person?.apellidoMaterno &&
      person?.primerNombre &&
      person?.categoria &&
      person?.fechainicial &&
      person?.sexo
    ) {
      setDisabled(false);
    } else setDisabled(true);
  }, [person]);
  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h6" color="initial">
          Lista de Asegurados
        </Typography>

        <div className={classes.form}>
          {Array.isArray(persons) && persons?.map((per, index) => (
            <CardName data={per} key={index} />
          ))}
        </div>
      </div>

      <div>
        <Typography variant="h6" color="initial">
          Datos generales persona Física
        </Typography>
        <div className={classes.form}>
          <div>
            <InputLabel htmlFor={"categoria"}>{"Categoría (*)"}</InputLabel>
            <TextField
              className={classes.input}
              variant="outlined"
              name={"categoria"}
              value={person?.categoria || ""}
              size="small"
              fullWidth
              onChange={handleChange}
              inputProps={{ maxLength: 250 }}
              select
            >
              {categories?.map((option) => {
                return (
                  <MenuItem key={option?.codigo} value={option?.codigo}>
                    {option?.descripcion}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>

          <div>
            <InputLabel htmlFor={"primer Nombre"}>
              {"Primer Nombre (*)"}
            </InputLabel>
            <TextField
              className={classes.input}
              variant="outlined"
              name={"primerNombre"}
              value={person?.primerNombre || ""}
              size="small"
              fullWidth
              onChange={handleChange}
              inputProps={{
                maxLength: 250,
                style: { textTransform: "uppercase" },
              }}
            />
          </div>

          <div>
            <InputLabel htmlFor={"segundoNombre"}>
              {"Segundo Nombre"}
            </InputLabel>
            <TextField
              className={classes.input}
              variant="outlined"
              name={"segundoNombre"}
              value={person?.segundoNombre || ""}
              size="small"
              fullWidth
              onChange={handleChange}
              inputProps={{
                maxLength: 250,
                style: { textTransform: "uppercase" },
              }}
            />
          </div>

          <div>
            <InputLabel htmlFor={"apellidoPaterno"}>
              {"Apellido Paterno (*)"}
            </InputLabel>
            <TextField
              className={classes.input}
              variant="outlined"
              name={"apellidoPaterno"}
              value={person?.apellidoPaterno || ""}
              size="small"
              fullWidth
              onChange={handleChange}
              inputProps={{
                maxLength: 250,
                style: { textTransform: "uppercase" },
              }}
            />
          </div>

          <div>
            <InputLabel htmlFor={"apellidoMaterno"}>
              {"Apellido Materno (*)"}
            </InputLabel>
            <TextField
              className={classes.input}
              variant="outlined"
              name={"apellidoMaterno"}
              value={person?.apellidoMaterno || ""}
              size="small"
              fullWidth
              onChange={handleChange}
              inputProps={{
                maxLength: 250,
                style: { textTransform: "uppercase" },
              }}
            />
          </div>

          <div>
            <InputLabel htmlFor={"sexo"}>{"Género (*)"}</InputLabel>
            <TextField
              className={classes.input}
              variant="outlined"
              name={"sexo"}
              value={person?.sexo || ""}
              size="small"
              fullWidth
              onChange={handleChange}
              inputProps={{ maxLength: 250 }}
              select
            >
              {generos.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>

          <div>
            <InputLabel htmlFor="dateEndoso" className={classes.label}>
              {"Fecha de Nacimiento (*)"}
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                id="dateEndoso"
                size="small"
                inputVariant="outlined"
                format={"dd-MM-yyyy"}
                fullWidth
                maxDate={new Date()}
                value={person?.fechainicial || null}
                onChange={(e) => {
                  handleChange({ target: { name: "fechainicial", value: e } });
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={disabled}
          onClick={handleSubmitData}
        >
          Agregar Persona
        </Button>
      </div>

      <AlertModal
        open={Boolean(alertData)}
        close={() => {
          setAlertData(undefined);
        }}
        SuccessText={"Si"}
        message={alertData?.message}
        title={"Atención"}
        success={async () => alertData.action()}
      />
    </div>
  );
};

export default HighRiskForm;
