import { useContext, useEffect } from "react";
import { InputLabel, TextField, Typography } from "@material-ui/core";
import { AuthContext } from "../../../../../context/context";
import useStyles from "./styles";
import { CardDocuments } from "../../../../../shared/components";

const FormEmail = ({ setShowButtons, showButtons }) => {
  const classes = useStyles();
  const { confirmData, setConfirmData } = useContext(AuthContext);

  /* methods */

  const handleChage = (e) => {
    setConfirmData({
      ...confirmData,
      emailData: { ...confirmData.emailData, [e.target.name]: e.target.value },
    });
  };
  useEffect(() => {
    if (!confirmData.emailData?.emailOne || confirmData.emailData?.length === 0)
      setShowButtons({ ...showButtons, disableSend: true });
    else setShowButtons({ ...showButtons, disableSend: false });
    // eslint-disable-next-line
  }, [confirmData.emailData]);
  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <div>
          <InputLabel htmlFor={"emailOne"}>{"Correo-e (*)"}</InputLabel>
          <TextField
            className={classes.input}
            variant="outlined"
            name={"emailOne"}
            size="small"
            fullWidth
            onChange={handleChage}
            inputProps={{ maxLength: 250 }}
          />
        </div>
        <div>
          <InputLabel htmlFor={"emailTwo"}>
            {"Correo-e 2 (Opcional)"}
          </InputLabel>
          <TextField
            className={classes.input}
            variant="outlined"
            name={"emailTwo"}
            size="small"
            fullWidth
            onChange={handleChage}
            inputProps={{ maxLength: 250 }}
          />
        </div>
      </div>
      <div>
        <InputLabel htmlFor={"mensaje"}>{"Mensaje"}</InputLabel>
        <TextField
          className={classes.input}
          variant="outlined"
          name={"mensaje"}
          multiline
          minRows={4}
          size="small"
          fullWidth
          onChange={handleChage}
          inputProps={{ maxLength: 250 }}
        />
      </div>
      <div>
        <Typography variant="h6" color="primary">
          Archivos
        </Typography>
        <div className={classes.documentsContainer}>
          {confirmData.condicionesGenerales?.data?.map((document, index) => (
            <CardDocuments text={document?.filename} key={index} />
          ))}
          {confirmData.documents?.map((document, index) => (
            <CardDocuments text={document.nombre} key={index} />
          ))}
          {confirmData.documentos?.map((document, index) => (
            <CardDocuments text={document.nombre} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FormEmail;
