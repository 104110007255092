import React, { useEffect, useState } from 'react';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { encode } from 'base64-arraybuffer';
import SuraSans from 'assets/fonts/SuraSans/SuraSans-Regular.otf'
import SuraSansBold from 'assets/fonts/SuraSans/SuraSans-Negrita.otf'

export const PdfPreview = ({ data }) => {
    const [pdfData, setPdfData] = useState(null);

    useEffect(() => {
        const createPdf = async () => {
            const pdfDoc = await PDFDocument.create();
            const page = pdfDoc.addPage([600, 680]);
            let regularFont;
            let boldFont;

            try {
                regularFont = await pdfDoc.embedFont(SuraSans);
                boldFont = await pdfDoc.embedFont(SuraSansBold);
            } catch (error) {
                console.error('No se pudo cargar las fuentes personalizadas, usando Helvetica como respaldo.', error);

                regularFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
                boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
            }

            const imageResponse = await fetch('https://images.prismic.io/imagessura/3c2b5aca-6973-423c-9d5d-c000308a1853_pud_agtclt_logoFooterMb_prod.png');
            const imageArrayBuffer = await imageResponse.arrayBuffer();
            const base64LogoSura = encode(imageArrayBuffer);
            const logoSuraImage = await pdfDoc.embedPng(`data:image/png;base64,${base64LogoSura}`);

            page.drawImage(logoSuraImage, {
                x: 450,
                y: 610,
                width: 100,
                height: 40,
            });

            const textSure = 'SEGUROS';

            page.drawText(textSure, {
                x: 350,
                y: 623,
                size: 14,
                font: boldFont,
                color: rgb(0, 51 / 255, 160 / 255)
            });

            const gerenteInfo = [
                "Seguros SURA, S.A. de C.V.",
                "Av. Insurgentes Sur 2475, pisos 22 y 23, col. Barrio Loreto,",
                "alcaldía Álvaro Obregón, C.P. 01090, Ciudad de México",
                "Tel. 55 5723 7999 y para reporte de siniestros: 800 911 7692",
            ];

            let startYInfo = 630;
            const lineHeightInfo = 12;

            gerenteInfo.forEach((line) => {
                page.drawText(line, {
                    x: 50,
                    y: startYInfo,
                    size: 10,
                    font: regularFont,
                    color: rgb(0, 51 / 255, 160 / 255),
                });
                startYInfo -= lineHeightInfo;
            });

            const title = 'Beneficiario Preferente';
            const titleSize = 20;
            const titleWidth = boldFont.widthOfTextAtSize(title, titleSize);
            const titlePositionX = (page.getWidth() - titleWidth) / 2;

            page.drawText(title, {
                x: titlePositionX,
                y: 500,
                size: titleSize,
                font: boldFont,
                color: rgb(0, 0, 0),
            });


            const fontSize = 14;
            const lineHeight = 16;
            const startX = 50;
            let startY = 450;

            const paragraphs = [
                `Mediante este endoso se da de alta la presente cláusula de beneficiario preferente.`,
                `En caso de ocurrir algún siniestro que causará pérdidas y/o daños materiales totales a los bienes amparados bajo la sección ${data?.dsSeccion}, la indemnización será pagadera preferente e irrevocablemente a favor de: ${data.nombreBeneficiario}, sin perjuicio del interés que como asegurado mantiene sobre pérdidas y/o daños parciales, que puedan ser reparables por el mismo asegurado, salvo pacto en contrario.`,
                `Hasta por el interés que le corresponda a la fecha del siniestro, en relación con el contrato de ${data.contrato} (arrendamiento, crédito con garantía, prendario, hipotecario, etc.) celebrado entre el asegurado y el beneficiario preferente, queda entendido que la presente cláusula solo podrá ser cancelada mediante previa autorización, por escrito, de los beneficiarios y que la responsabilidad de la compañía nunca será mayor a la contratada en esta póliza.`,
                `Así mismo, queda entendido y convenido que cualquier aumento en el monto a indemnizar como consecuencia de la demora en la reconstrucción de los bienes motivada por la aplicación de esta cláusula, correrá exclusivamente a cargo del asegurado.`,
            ];

            paragraphs.forEach((paragraph) => {
                const words = paragraph.split(' ');
                let line = '';
                let lineWidth = 0;

                words.forEach((word, index) => {
                    let testLine = line + word + ' ';
                    let testLineWidth = regularFont.widthOfTextAtSize(testLine, fontSize);

                    if (testLineWidth > page.getWidth() - 2 * startX) {
                        if (regularFont.widthOfTextAtSize(word, fontSize) > page.getWidth() - 2 * startX) {
                            let splitWord = word;
                            while (regularFont.widthOfTextAtSize(splitWord, fontSize) > page.getWidth() - 2 * startX) {
                                let part = '';

                                for (let i = 0; i < splitWord.length; i++) {
                                    let testPart = part + splitWord[i];
                                    if (regularFont.widthOfTextAtSize(line + testPart + '-', fontSize) > page.getWidth() - 2 * startX) {
                                        break;
                                    }
                                    part = testPart;
                                }

                                page.drawText(line + part + '-', {
                                    x: startX,
                                    y: startY,
                                    size: fontSize,
                                    font: regularFont,
                                    color: rgb(0, 0, 0),
                                });

                                splitWord = splitWord.slice(part.length);
                                line = '';
                                startY -= lineHeight;
                            }
                            word = splitWord;
                            testLine = word + ' ';
                            testLineWidth = regularFont.widthOfTextAtSize(testLine, fontSize);
                        }

                        if (line.trim()) {
                            const spaceCount = line.trim().split(' ').length - 1;
                            const extraSpace = (page.getWidth() - 2 * startX - lineWidth) / (spaceCount || 1);

                            let xPosition = startX;
                            line.trim().split(' ').forEach((part, i) => {
                                page.drawText(part, {
                                    x: xPosition,
                                    y: startY,
                                    size: fontSize,
                                    font: regularFont,
                                    color: rgb(0, 0, 0),
                                });
                                xPosition += regularFont.widthOfTextAtSize(part + ' ', fontSize) + extraSpace;
                            });
                            startY -= lineHeight;
                        }

                        line = word + ' ';
                        lineWidth = regularFont.widthOfTextAtSize(word + ' ', fontSize);
                    } else {
                        line = testLine;
                        lineWidth = testLineWidth;
                    }
                });
                if (line.trim()) {
                    page.drawText(line.trim(), {
                        x: startX,
                        y: startY,
                        size: fontSize,
                        font: regularFont,
                        color: rgb(0, 0, 0),
                    });

                    startY -= lineHeight;
                }

                startY -= lineHeight;
            });

            page.drawText('Previo de endoso', {
                x: 80,
                y: 100,
                size: 76,
                font: regularFont,
                color: rgb(0, 0, 0),
                opacity: 0.1,
                rotate: degrees(35),
            });

            const pdfBytes = await pdfDoc.save();
            setPdfData(URL.createObjectURL(new Blob([pdfBytes], { type: 'application/pdf' })));
        };

        createPdf();
    }, [data]);

    return (
        <div>
            {pdfData && <embed src={pdfData} type="application/pdf" width="100%" height="600px" style={{
                borderRadius: '10px', border: 'none'
            }} />}
        </div>
    );
};
