import { useContext, useEffect } from "react";
import { AuthContext } from "../../../../../context/context";
import { InfoTableHighRisk, SectionSelector } from "./components";
import { EndosoContext } from "pages/endoso/context";

export const AltaRiesgo12 = ({ setMessage, setShowMessage, idSolicitud }) => {
  const { persons, setPersons } = useContext(AuthContext);

  useEffect(() => {
    setPersons([]);
  }, []); // eslint-disable-line

  const { showButtons } = useContext(EndosoContext);
  if (!showButtons?.infoTable)
    return (
      <SectionSelector
        setMessage={setMessage}
        setShowMessage={setShowMessage}
        idSolicitud={idSolicitud}
      />
    );

  return <InfoTableHighRisk rows={persons} title="Asegurados Guardados" />;
};
