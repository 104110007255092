import { useContext, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { AuthContext } from "context/context";
import endoso from "services/endoso";
import { UploadExcel } from "../";
import useStyles from "./styles";
import HighRiskForm from "pages/endoso/sections/formsSection/highRiskForm";

export const SectionSelector = ({ setMessage, setShowMessage, idSolicitud }) => {
  const classes = useStyles();
  const { setTimer } = useContext(AuthContext);
  const [selected, setSelected] = useState();
  const [disabled, setDisabled] = useState(false);

  const activate = () => setDisabled(false);
  const download = () => {
    setTimer(0);
    setDisabled(true);
    endoso.descargarLayout(activate);
  };

  const returntoMain = () => setSelected(undefined);
  if (selected === "tradicional")
    return (
      <HighRiskForm setMessage={setMessage} setShowMessage={setShowMessage}
        idSolicitud={idSolicitud} />
    );

  if (selected === "archivo")
    return <UploadExcel returntoMain={returntoMain} />;
  return (
    <div>
      <Typography variant="h6">
        ¿Cómo desea agregar los nuevos asegurados?
      </Typography>
      <Grid container spacing={2} className={classes.container}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => setSelected("tradicional")}
          >
            Agregar de manera tradicional
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => setSelected("archivo")}
          >
            Agregar por archivo de asegurados
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={download}
            disabled={disabled}
          >
            Descargar Plantilla de Layout
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
