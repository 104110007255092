import { Fragment, useContext, useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { endoso } from "../../../../../services";
import { AuthContext } from "../../../../../context/context";
import useStyles from "./styles";
import { FindInPage } from "@material-ui/icons";
import { Filter } from "shared/components/filter";
import { Spinner } from "shared/components";

const FormTable = ({
  title,
  check = false,
  rows,
  setFormDta,
  setSpinner,
  setMessage,
  setShowMessage,
  data,
  formDta,
  setShowButtons,
  showButtons,
  setRows,
  type = 1,
  actionHeader = false,
}) => {
  const classes = useStyles();
  const { setTimer, typeEnd, setShowBack, setConfirmData } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [disableOncLick, setDisableOnclik] = useState(check);

  useEffect(() => {
    if (actionHeader) setShowBack(true);
  }, [actionHeader, setShowBack]);

  const cleanDescription = (description) => {
    return description ? description.replace(" NULL", "").trim() : "";
  };
  const cleanedRows = rows?.niveles?.map((item) => ({
    ...item,
    descripcion: cleanDescription(item.descripcion),
  }));

  const [filteredRows, setFilteredRows] = useState(cleanedRows || []);

  const combineDocumentsAndCondiciones = () => {
    const documentos = rows?.documentos?.map((doc) => ({
      id: doc.id,
      nombre: doc.nombre,
      url: doc.url,
    })) || [];

    const condicionesGenerales = rows?.condicionesGenerales?.data?.map((cond, index) => ({
      id: `cg_${index + 1}`,
      nombre: cond.filename,
      url: `data:${cond.type};base64,${cond.content}`,
    })) || [];

    return [...condicionesGenerales, ...documentos];
  };

  useEffect(() => {
    setConfirmData(combineDocumentsAndCondiciones());
  }, []); // eslint-disable-line

  const columns = [
    {
      field: "codigo",
      headerName: rows?.titulo1 || "Número",
      flex: 0.4,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "descripcion",
      headerName: rows?.titulo2 || (check ? "Nombre" : "Asegurado"),
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
  ];

  const columnsCheck = [
    {
      field: "nombre",
      headerName: "Documentos",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "url",
      headerName: actionHeader ? "Acción" : "",
      headerClassName: "header",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <IconButton
          onClick={() => handleDownload(row)}
          className={classes.documentButton}
        >
          <FindInPage />
        </IconButton>
      ),
    },
  ];

  const { setCoberturas, setPersons, confirmData } =
    useContext(AuthContext);

  /* methods */
  const submitData = async (row) => {
    setSpinner(true);
    setTimer(0);

    if (data.tipend !== 8) {
      const solicitud = formDta.noSolicitud;
      const configBody = {
        solicitud: formDta.noSolicitud,
        nivel: formDta.nivel,
        llave: row.codigo,
        idPersona: null,
        idDomicilio: null,
      };
      const configResponse = await endoso.configuration(configBody);
      if (configResponse.data?.codigo === 1) {
        const data = { ...configResponse.data, nivel: formDta.nivel };
        const codigo = rows?.niveles[0].codigo;
        setFormDta({
          ...data,
          noSolicitud: solicitud,
          levelCode: codigo,
          suplemLogico: formDta.suplemLogico,
          llave: row.codigo,
        });
        setShowButtons({
          ...showButtons,
          formulario: true,
          formTable: false,
          guardarEndoso: true,
        });
      } else {
        setMessage({
          text:
            configResponse.data?.mensaje || "Ocurrio un error con su solicitud",
          severity: "warning",
        });
        setShowMessage(true);
      }
    } else {
      const body = {
        clave: row.codigo,
        nivel: formDta.nivel,
        solicitud: formDta.noSolicitud,
      };
      const responseGetLevel = await endoso.getLevel(body);
      if (responseGetLevel.data?.codigo === 1) {
        setRows({ ...responseGetLevel.data, llave: row.codigo });

        const body = {
          oficina: data.oficina,
          poliza: data.poliza,
          ramo: data.ramo,
        };
        const coberturasResponse = await endoso.getCoberturas(body);
        if (coberturasResponse.data?.codigo === 1) {
          setCoberturas(coberturasResponse.data);
          setShowButtons({
            ...showButtons,
            multiCheck: true,
            guardarEndoso: true,
          });
        } else {
          setMessage({
            text:
              responseGetLevel.data?.mensaje ||
              "Ocurrio un error con su solicitud",
            severity: "warning",
          });
          setShowMessage(true);
        }
      } else {
        setMessage({
          text:
            responseGetLevel.data?.mensaje ||
            "Ocurrio un error con su solicitud",
          severity: "warning",
        });
        setShowMessage(true);
      }
    }
    setSpinner(false);
  };

  const preloadRows = rows.condicionesGenerales?.data?.length

  const [selectionModel, setSelectionModel] = useState(
    preloadRows > 0
      ? rows?.condicionesGenerales?.data?.map((cond, index) => `cg_${index + 1}`)
      : []
  );

  useEffect(() => {
    if (preloadRows > 0) {
      const allIds = rows?.documentos?.map(doc => doc.id) || [];
      const allCondiciones = rows?.condicionesGenerales?.data?.map((cond, index) => `cg_${index + 1}`) || [];

      setSelectionModel([...allIds, ...allCondiciones]);
    }
  }, [preloadRows, rows]);

  const handleSelectionChange = (newSelection) => {
    if (!preloadRows || preloadRows === 0) {
      setSelectionModel(newSelection);
    }
  };

  const handleChageCheck = (e) => {
    if (type === 2) {
      const selectedDocs = new Set(e);
      setConfirmData({
        ...confirmData,
        documents: rows?.documentos?.filter((doc) =>
          selectedDocs?.has(doc.url)
        ),
      });
    } else {
      setPersons(e);
    }
  };

  useEffect(() => {
    if (typeEnd === 17 || typeEnd === 13) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [typeEnd]);

  useEffect(() => {
    if (rows?.niveles?.length === 1)
      submitData(rows?.niveles[0]); // eslint-disable-next-line
  }, []);

  const handleSearch = (filteredData) => {
    const filteredSet = new Set(filteredData);
    setFilteredRows(
      cleanedRows?.filter(
        (item) =>
          filteredSet.has(item.codigo) || filteredSet.has(item.descripcion)
      ) || []
    );
  };
  const downloadFile = (url, filename) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const handleDownload = (row) => {
    if (row.url) {
      downloadFile(row.url, row.nombre);
      if (row.id && !row.id.startsWith("cg_")) {
        rows?.condicionesGenerales?.data?.forEach((cond, index) => {
          downloadFile(
            `data:${cond.type};base64,${cond.content}`,
            cond.filename
          );
        });
      }
    }
  };


  return (
    <Box>
      {show && type === 1 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
          }}
        >
          <Filter
            placeholder="Consultar..."
            data={cleanedRows?.flatMap((i) => [i.codigo, i.descripcion, i.codigo + ' ' + i.descripcion])}
            search={handleSearch}
          />
        </Box>
      )}
      <Fragment>
        <Box className={classes.headerComponent}>
          <Typography variant="h6" className={classes.title} align="left">
            <strong>{title}</strong>
          </Typography>
        </Box>
        <DataGrid
          columns={type === 1 ? columns : columnsCheck}
          rows={show && type === 1 ? filteredRows : rows?.niveles || combineDocumentsAndCondiciones() || []}
          className={classes.root}
          disableSelectionOnClick={!check}
          disableColumnMenu
          rowsPerPageOptions={[4]}
          pageSize={4}
          onCellClick={(actionHeader || preloadRows > 0) ? undefined : !disableOncLick ? (e) => submitData(e.row, setDisableOnclik) : null}
          pagination
          checkboxSelection={preloadRows > 0 ? undefined : check && !rows?.condicionesGenerales}
          getRowId={(row) => row.id || row.codigo || `row_${Math.random().toString(36).substring(2, 9)}`}
          selectionModel={selectionModel}
          onSelectionModelChange={(!preloadRows || preloadRows === 0) ? handleChageCheck : handleSelectionChange}
          isRowSelectable={(params) => {
            return (!preloadRows || preloadRows === 0) || !selectionModel.includes(params.id);
          }}
          localeText={{
            noRowsLabel: actionHeader && rows ? <Spinner /> : 'No se encontró información relacionada',
            footerRowSelected: (count) => (actionHeader || preloadRows > 0) ? '' : `${count} row selected`,
          }}
        />

      </Fragment>
    </Box>
  );
};

export default FormTable;
