import {
  rulesByOneTwoThree,
  rulesByNineTenEleven,
  rulesByFiveSeven,
  rulesBySix,
  rulesByeight,
  rulesByThirteen,
  rulesBySeventeen,
  rulesByTwenty,
  useRulesBy18,
  useRulesBy16,
  useRulesBy21,
  useRulesBy23,
  useRulesBy12,
} from "./ruleByTypes";
import { useRulesBy14 } from "./ruleByTypes/rulesBy-14";
import { useRulesBy22 } from "./ruleByTypes/rulesBy-22";

export const useSubmitData = () => {
  const { handleSubmit18 } = useRulesBy18();
  const { rulesByTwelve  } = useRulesBy12();
  const { rulesByFourteen } = useRulesBy14();
  const { rulesBySixteen } = useRulesBy16();
  const { confirmEndoso } = useRulesBy21();
  const { handleConfirm } = useRulesBy22();
  const { handleRows23 } = useRulesBy23();
  const handleSubmitData = async (props) => {
    const {
      setSpinner,
      data,
      oldData,
      dataSend,
      setMessage,
      setShowMessage,
      direction,
      formDta,
      endoso,
      setInfoTable,
      setShowButtons,
      showButtons,
      coberturas,
      rowsTable,
      persons,
      setPersons,
      setAlertData,
      setCheckedPersons,
      createMessage,
      history,
    } = props;
    setSpinner(true);
    if (data.tipend === 1 || data.tipend === 2 || data.tipend === 3) {
      await rulesByOneTwoThree(
        data,
        oldData,
        dataSend,
        setMessage,
        setShowMessage,
        direction,
        formDta,
        endoso,
        setInfoTable,
        setShowButtons,
        showButtons
      );
    }
    if (
      data.tipend === 9 ||
      data.tipend === 10 ||
      data.tipend === 11 ||
      data.tipend === 19
    ) {
      await rulesByNineTenEleven(
        data,
        oldData,
        dataSend,
        setMessage,
        setShowMessage,
        formDta,
        endoso,
        setInfoTable,
        setShowButtons,
        showButtons
      );
    }
    if (data.tipend === 5 || data.tipend === 7) {
      await rulesByFiveSeven(
        data,
        oldData,
        dataSend,
        setMessage,
        setShowMessage,
        formDta,
        endoso,
        setInfoTable,
        setShowButtons,
        showButtons
      );
    }
    if (data.tipend === 6) {
      await rulesBySix(
        data,
        oldData,
        dataSend,
        setMessage,
        setShowMessage,
        formDta,
        endoso,
        setInfoTable,
        setShowButtons,
        showButtons
      );
    }
    if (data.tipend === 8) {
      await rulesByeight(
        data,
        setMessage,
        setShowMessage,
        formDta,
        endoso,
        setInfoTable,
        setShowButtons,
        showButtons,
        coberturas,
        rowsTable
      );
    }
    if (data.tipend === 12) {
      const solicitud = formDta.noSolicitud;
      await rulesByTwelve(
        solicitud,
        data,
        setMessage,
        setShowMessage,
        formDta,
        endoso,
        setShowButtons,
        showButtons,
        persons,
        setPersons,
        setAlertData,
        dataSend,
        createMessage,
      );
    }
    if (data.tipend === 13 || data.tipend === 15) {
      await rulesByThirteen(
        data,
        setMessage,
        setShowMessage,
        formDta,
        endoso,
        setShowButtons,
        showButtons,
        persons,
        setPersons,
        history
      );
    }
    if (data.tipend === 14) {
      await rulesByFourteen(data, formDta, persons, setPersons);
    }
    if(data.tipend === 21){
      await confirmEndoso(formDta.noSolicitud)
    }
    if(data.tipend ===22) {
      await handleConfirm();
    }
    if(data.tipend === 23){
      await handleRows23();
    }
    if (data.tipend === 16) {
      const info = { ...data, solicitud: formDta.noSolicitud };

      await rulesBySixteen(
        info,
        createMessage,
        dataSend,
        setShowButtons,
        showButtons
      );
    }
    if (data.tipend === 17) {
      await rulesBySeventeen(
        data,
        setMessage,
        setShowMessage,
        formDta,
        setShowButtons,
        showButtons,
        persons,
        setPersons,
        setAlertData,
        setCheckedPersons
      );
    }
    if (data.tipend === 20) {
      await rulesByTwenty(
        dataSend,
        data,
        formDta,
        setMessage,
        setShowMessage,
        showButtons,
        setShowButtons
      );
    }
    if (data.tipend === 18) {
      await handleSubmit18(data);
    }

    setSpinner(false);
  };

  return { handleSubmitData };
};
