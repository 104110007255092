import { useContext } from "react";
import { asyncForEach, dateFomated, formatSex } from "..";
import { endoso } from "../../../../services";
import { AltaContext } from "../../context";
import { AuthContext } from "context/context";

const generateBody = (data, person, type) => {
  const removeItems =
    type === "T"
      ? ["fechainicial", "id", "familiares", "tipend"]
      : ["fechainicial", "id", "parentId", "tipend"];
  const body = {
    ...data,
    ...person,
    rfc: null,
    tipoPersona: "0132",
    asegurados: null,
    tipoIdentificador: "0",
    personaFoM: "1",
    razonSocial: "",
    fchNacimineto: dateFomated(person.fchNacimineto),
    antiguedad: dateFomated(data.fechainicial),
    sexo: formatSex(person.sexo),
    titular: type !== "T" ? person.titular : null,
  };
  removeItems.forEach((item) => {
    delete body[item];
  });

  return body;
};

const isSaved = (savedPersons, person) => {
  return Boolean(savedPersons.find((pers) => pers.id === person.id));
};

const errorText = {
  emply: "Para generar el endoso se debe agregar al menos un asegurado nuevo",
  default: "Ocurrio un error al guardar el asegurado",
  invalidType:
    "El archivo no tiene una extensión permitida, por favor verifique.",
};

export const useRulesBy16 = () => {
  const { setBadPersons } = useContext(AltaContext);
  const { setShowContinue } = useContext(AuthContext);
  const rulesBySixteen = async (
    data,
    createMessage,
    dataSend,
    setShowButtons,
    showButtons
  ) => {
    const { familiares, titulares, addPersonsSaved, file } = dataSend;
    const newTitulares = titulares.filter((titular) => !titular.titular);
    let alertShown = false;

    if (!file) {
      /* methods */
      const errorCase = (mensaje) => {
        createMessage({
          text: mensaje || errorText.default,
          variant: "warning",
        });
        return 0;
      };

      const savePerson = async (situacion, index) => {
        const person = generateBody(data, situacion, situacion.parentesco);
        const altaRes = await endoso.alta607(person);
        if (altaRes?.data?.codigo === 1 && altaRes?.data?.alerta && !alertShown) {
          createMessage({
            text: altaRes?.data?.alerta,
            variant: "info",
            action: true,
          });
          alertShown = true;
          setShowContinue(true);
        }
        if (altaRes?.data?.codigo === 1) {
          const situacionRes = await endoso.guardarSituacion({
            situacion: altaRes.data?.mensaje,
            solicitud: person.solicitud,
          });
          if (situacionRes.data?.codigo === 1) {
            return {
              id: situacion.id,
              saved: true,
              titular: altaRes.data.mensaje,
            };
          } else return errorCase(situacionRes.data?.mensaje);
        } else return errorCase(altaRes?.data?.mensaje);
      };

      const guardarEndoso = async (ptitulares, pfamiliares) => {
        const diferenciaRes = await endoso.diferencia({
          solicitud: data.solicitud,
        });
        const codigo = diferenciaRes?.data?.codigo;
        if (codigo === 1 || codigo === "1") {
          const clausulaRes = await endoso.clausula({
            solicitud: data.solicitud,
          });
          const codigo = clausulaRes?.data?.codigo;
          if (codigo === 1 || codigo === "1") {
            const savedTitulares = ptitulares.filter(
              (titular) => titular.saved
            );
            const savedFamiliar = pfamiliares.filter(
              (familiar) => familiar.saved
            );
            const results = [...savedFamiliar, ...savedTitulares];
            addPersonsSaved(results, diferenciaRes?.data);
            setShowButtons({ ...showButtons, multiTable: true });
          } else
            createMessage({
              text: clausulaRes?.data?.mensaje || errorText.default,
              variant: "warning",
            });
        } else
          createMessage({
            text: diferenciaRes?.data?.mensaje || errorText.default,
            variant: "warning",
          });
      };

      if (familiares.length === 0 && newTitulares.length === 0) {
        createMessage({
          text: errorText.emply,
          variant: "warning",
        });
      } else {
        const saveTitulares = await asyncForEach(newTitulares, savePerson).then(
          (e) => e
        );
        const titularesSaved = saveTitulares.filter((titular) => titular !== 0);
        const mergeTitulares = titulares.map((titular) => ({
          ...titular,
          ...(isSaved(titularesSaved, titular) &&
            titularesSaved.find((per) => per.id === titular.id)),
        }));

        const filteredFamiliares = familiares.filter((familiar) => {
          if (
            Boolean(
              mergeTitulares.find(
                (titular) =>
                  titular.id === familiar.parentId && Boolean(titular.titular)
              )
            )
          )
            return familiar;
          return undefined;
        });
        if (filteredFamiliares.length > 0) {
          const familiarTitular = filteredFamiliares.map((familiar) => ({
            ...familiar,
            titular: mergeTitulares.find(
              (titular) => titular.id === familiar.parentId
            ).titular,
          }));
          const saveFamiliares = await asyncForEach(
            familiarTitular,
            savePerson
          ).then((e) => e);
          const familiaresSaved = saveFamiliares.filter(
            (familiar) => familiar !== 0
          );
          const mergeFamiliares = familiares.map((familiar) => ({
            ...familiar,
            ...(isSaved(familiaresSaved, familiar) &&
              familiaresSaved.find((per) => per.id === familiar.id)),
          }));

          await guardarEndoso(mergeTitulares, mergeFamiliares);
        } else await guardarEndoso(mergeTitulares, []);
      }
    } else {
      // guardar archivo
      const fileExtension = file.name.split(".").pop();
      if (fileExtension === "xlsx") {
        setBadPersons([]);
        const uploadResponse = await endoso.uploadFile(
          file,
          data.oficina,
          data.ramo,
          data.poliza,
          data.solicitud
        );
        if (uploadResponse.data?.codigo === "1") {
          const situacions = uploadResponse.data?.altas607;
          let lastTitular = null;
          const fileErrors = uploadResponse.data?.errores ?? [];
          /* if the file have errors */
          const getPersonsWithErrors = (notSavedPersons) => {
            if (fileErrors.length) {
              const mapErrors = fileErrors.map((error) => ({
                nombre: error.nombre,
                error: error.mensaje,
              }));
              return [...(notSavedPersons ?? []), ...(mapErrors ?? [])];
            }
            return notSavedPersons;
          };
          /* ----------- */

          const errorCase = (mensaje, person) => {
            createMessage({
              text: mensaje || errorText.default,
              variant: "warning",
            });
            return { ...person, saved: false, error: mensaje };
          };

          const savePerson = async (situacion, index) => {
            const person = { ...situacion };
            if (person.parentesco !== "T") person.titular = lastTitular;

            const altaRes = await endoso.alta607(person);
            if (altaRes?.data?.codigo === 1 && altaRes?.data?.alerta && !alertShown) {
              createMessage({
                text: altaRes?.data?.alerta,
                variant: "info",
                action: true,
              });
              alertShown = true;
              setShowContinue(true);
            }

            if (altaRes?.data?.codigo === 1) {
              const situacionRes = await endoso.guardarSituacion({
                situacion: altaRes.data?.mensaje,
                solicitud: person.solicitud,
              });

              if (situacionRes.data?.codigo === 1) {
                if (person.parentesco === "T")
                  lastTitular = altaRes?.data?.mensaje;
                return {
                  ...person,
                  id: situacion.id ?? new Date().getTime(),
                  saved: true,
                  titular: altaRes.data.mensaje,
                };
              } else return errorCase(situacionRes.data?.mensaje, person);
            } else return errorCase(altaRes?.data?.mensaje, person);
          };

          const guardarEndoso = async (ptitulares, badSituacions) => {
            const diferenciaRes = await endoso.diferencia({
              solicitud: data.solicitud,
            });
            const codigo = diferenciaRes?.data?.codigo;
            if (codigo === 1 || codigo === "1") {
              const clausulaRes = await endoso.clausula({
                solicitud: data.solicitud,
              });
              const codigo = clausulaRes?.data?.codigo;
              if (codigo === 1 || codigo === "1") {
                setBadPersons(getPersonsWithErrors(badSituacions));
                addPersonsSaved(ptitulares, diferenciaRes?.data);
                setShowButtons({ ...showButtons, multiTable: true });
              } else
                createMessage({
                  text: clausulaRes?.data?.mensaje || errorText.default,
                  variant: "warning",
                });
            } else
              createMessage({
                text: diferenciaRes?.data?.mensaje || errorText.default,
                variant: "warning",
              });
          };
          const savedSituacions = await asyncForEach(situacions, savePerson);
          const situacionsFinished = savedSituacions.filter(
            ({ saved }) => saved
          );
          const badSituacions = savedSituacions.filter(({ saved }) => !saved);
          if (situacionsFinished.length) {
            await guardarEndoso(situacionsFinished, badSituacions);
          }
        } else {
          if (uploadResponse.data?.errores?.length) {
            const errorMessage = uploadResponse.data?.errores[0]?.mensaje ?? "";
            createMessage({
              text: `No se pudieron agregar todas las personas, por favor verifique que los datos sean correctos: ${errorMessage}`,
              variant: "warning",
            });
          }
        }
      } else {
        createMessage({
          text: errorText.invalidType,
          variant: "warning",
        });
      }
    }
  };

  return { rulesBySixteen };
};
